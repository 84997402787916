import Banner from "@/components/Banner";
import Skills from "@/components/Skills";

const Home = () => {
  return (
    <>
      <Banner />
      <Skills />
    </>
  );
};

export default Home;
